import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['hideable', 'showable', 'url'];
  }

  show({ target }) {
    const scope = this.element.dataset.visibilityScope; // Retrieve scope
    const visibilityId = target.dataset.visibilityId;

    this.urlTargets.forEach((urlTarget) => {
      const url = new URL(urlTarget.href);
      url.searchParams.set('view', visibilityId);
      urlTarget.href = url;
    });

    const scopedHideableTargets = scope
      ? this.hideableTargets.filter((t) =>
          t.closest(`[data-visibility-scope="${scope}"]`),
        )
      : this.hideableTargets;

    const scopedShowableTargets = scope
      ? this.showableTargets.filter((t) =>
          t.closest(`[data-visibility-scope="${scope}"]`),
        )
      : this.showableTargets;

    scopedHideableTargets.forEach((hideableTarget) => {
      const isMatch = hideableTarget.dataset.visibilityId === visibilityId;
      hideableTarget.hidden = !isMatch;
      hideableTarget.classList.toggle('hidden', !isMatch);
    });

    scopedShowableTargets.forEach((showableTarget) => {
      const isMatch = showableTarget.dataset.visibilityId === visibilityId;
      showableTarget.hidden = isMatch;
      showableTarget.classList.toggle('hidden', isMatch);
    });
  }
}
