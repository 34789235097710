import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['panel', 'openIcon', 'closeIcon'];

  connect() {
    this.updateIcons(); // Ensure icons match the initial panel state
  }

  toggle() {
    const panel = this.panelTarget;
    const isPanelOpen = panel.classList.contains('laptop:w-1/2');

    // Toggle panel width
    panel.classList.toggle('laptop:w-1/2', !isPanelOpen);
    panel.classList.toggle('laptop:w-[0px]', isPanelOpen);

    this.updateIcons();
  }

  updateIcons() {
    const isPanelOpen = this.panelTarget.classList.contains('laptop:w-1/2');

    // Ensure icons are in the correct state
    if (window.innerWidth <= 768) {
      return;
    }
    this.openIconTarget.classList.toggle('hidden', isPanelOpen);
    this.closeIconTarget.classList.toggle('hidden', !isPanelOpen);
  }
}
