import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filters', 'leftControls', 'rightControls', 'projectType'];

  connect() {
    this.updateVisibility(); // Initial check on page load

    // Add event listeners
    window.addEventListener('resize', this.updateVisibility.bind(this));
    this.filtersTarget.addEventListener(
      'scroll',
      this.updateVisibility.bind(this),
    );

    // Ensure the active filter is visible without triggering a reset
    requestAnimationFrame(() => this.ensureActiveProjectTypeInView());
  }

  disconnect() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('resize', this.updateVisibility.bind(this));
    this.filtersTarget.removeEventListener(
      'scroll',
      this.updateVisibility.bind(this),
    );
  }

  ensureActiveProjectTypeInView() {
    const { filterProjectType } = this.element.dataset;
    if (filterProjectType) {
      const projectTypeElement = this.projectTypeTargets.find(
        (el) => el.dataset.projectType === filterProjectType,
      );
      if (projectTypeElement) {
        projectTypeElement.scrollIntoView({
          behavior: 'instant',
          block: 'end',
          inline: 'center',
        });
      }
    }
  }

  scrollLeft() {
    this.scrollContainer(-0.7);
  }

  scrollRight() {
    this.scrollContainer(0.7);
  }

  scrollContainer(direction) {
    const container = this.filtersTarget;
    const scrollAmount = container.clientWidth * direction;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    this.updateVisibility();
  }

  updateVisibility() {
    const container = this.filtersTarget;

    // Check if content overflows
    const isScrollable = container.scrollWidth > container.clientWidth;

    if (!isScrollable) {
      this.hideElements([this.leftControlsTarget, this.rightControlsTarget]);
      return;
    }

    // Update visibility based on scroll position
    this.toggleVisibility(
      this.leftControlsTarget,
      Math.ceil(container.scrollLeft) === 0,
    );
    this.toggleVisibility(
      this.rightControlsTarget,
      Math.ceil(container.scrollLeft + container.clientWidth) >=
        Math.floor(container.scrollWidth),
    );
  }

  toggleVisibility(element, hidden) {
    element.classList.toggle('opacity-0', hidden);
    element.classList.toggle('pointer-events-none', hidden);
  }

  hideElements(elements) {
    elements.forEach((element) => {
      element.classList.add('opacity-0', 'pointer-events-none');
    });
  }
}
